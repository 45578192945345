import { FC, useEffect, useState } from 'react';
import PagedTable from '../../sorted-table/PagedTable';
import Loader from '../../loader/Loader';
import Tooltip from '../../tooltip/Tooltip';
import { showToast } from '../../../services/toast.service';
import { centsToDollars } from '../../../services/account.service';
import { getPaymentTransactions, getPaymentTransactionsOdata } from '../../../api/paymentGatewayApi';
import { PaymentTransaction, StandardParamsToODataParams } from '../../../types/interfaces';
import { toastMessages } from '../../../constants/errorMessages';
import { APP_PATHS } from '../../../constants/routePaths';
import './paymentHistory.scss';

type PaymentHistoryProps = {
  accountId?: number;
  refresh?: number;
};

const PaymentHistory: FC<PaymentHistoryProps> = ({ accountId, refresh }) => {
  const [paymentHistory, setPaymentHistory] = useState<PaymentTransaction[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const currentRouteLocation = location?.pathname?.split('/');
  const appPage = currentRouteLocation && currentRouteLocation[2];

  useEffect(() => {
    handleGetPaymentHistory();
  }, [accountId, refresh]);

  const handleGetPaymentHistory = async () => {
    try {
      setIsLoading(true);
      if (accountId) {
        const params = { filter: `accountId eq ${accountId}`, sort: '^billedDate' };
        const { data: paymentHistoryResponse } = await getPaymentTransactionsOdata(StandardParamsToODataParams(params));
        setPaymentHistory(paymentHistoryResponse);
      } else {
        const params = { sort: '^billedDate' };
        const { data: paymentHistoryResponse } = await getPaymentTransactions(params);
        setPaymentHistory(paymentHistoryResponse);
      }

      setIsLoading(false);
    } catch (error) {
      showToast.error(toastMessages.SOMETHING_WENT_WRONG);
    }
  };

  const getPaymentMethod = (history: PaymentTransaction) => {
    return (
      <>
        {history?.method === 'Direct'
          ? 'Direct'
          : `${history?.method === 'RecurringCard' ? 'Card' : 'ACH'}` + `....${history?.maskedAccountNumber.slice(-4)}`}
        {history?.method === 'Direct' && appPage && Object.values(APP_PATHS).includes(appPage) && (
          <Tooltip>{history?.notes ?? '-NA-'}</Tooltip>
        )}
      </>
    );
  };

  return (
    <div className="payment-history-container">
      <PagedTable
        headers={[
          { displayName: 'Bill Date', sortValue: 'billedDate' },
          { displayName: 'Amount' },
          { displayName: 'Average Daily Balance' },
          { displayName: 'Payment Method' },
          { displayName: 'Reference Id' },
          { displayName: 'Status' },
        ]}
        sortBy="^billedDate"
        filter={accountId ? `accountId eq ${accountId}` : ''}
        getData={params => {
          return accountId ? getPaymentTransactionsOdata(StandardParamsToODataParams(params)) : getPaymentTransactions(params);
        }}
        buildRow={history => {
          return [
            `${new Date(history?.billedDate).toLocaleDateString('en-US')}`,
            `$${centsToDollars(history?.costInCents)}`,
            history?.averageDailyBalance,
            getPaymentMethod(history),
            history?.transactionReferenceId,
            history?.status,
          ];
        }}
        height={240}
        pageSize={6}
        noResultsMessage="No results found."
        refresh={refresh}
      />

      <div className="xs-payment-history">
        {!isLoading && paymentHistory?.length === 0 ? (
          <div className="result-item">{'No results found.'}</div>
        ) : (
          <>
            {paymentHistory &&
              paymentHistory.map((history, index) => {
                return (
                  <div key={history?.transactionReferenceId + index} className="card payment-history-card">
                    <div className="payment-history-info">
                      <div className="payment-history-content">
                        <label>Bill Date : </label>
                        <label>{` ${new Date(history?.billedDate).toLocaleDateString('en-US') ?? '-'}`}</label>
                      </div>
                      <div className="payment-history-content">
                        <label>Amount : </label>
                        <label>{`$${centsToDollars(history?.costInCents) ?? '0'}`}</label>
                      </div>
                      <div className="payment-history-content">
                        <label>Average Daily Balance : </label>
                        <label>{` ${history?.averageDailyBalance ?? '-'}`}</label>
                      </div>
                    </div>
                    <div className="payment-history-info">
                      <div className="payment-history-content">
                        <label>Payment Method : </label>
                        <label>{getPaymentMethod(history)}</label>
                      </div>
                      <div className="payment-history-content">
                        <label>Reference Id : </label>
                        <label>{` ${history?.transactionReferenceId ?? '-'}`}</label>
                      </div>
                      <div className="payment-history-content">
                        <label>Status : </label>
                        <label>{` ${history?.status ?? '-'}`}</label>
                      </div>
                    </div>
                  </div>
                );
              })}
          </>
        )}
        {isLoading && <Loader addedSpace loaderSize="small" />}
      </div>
    </div>
  );
};

export default PaymentHistory;
